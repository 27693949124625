@import 'src/variables.scss';
@import 'src/toaster.scss';

body {
    font-family: $font-family;
    margin: 0;
    padding: 0;
}

input, textarea {
    width: 100%;
    outline: none;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $text-secondary;
}

textarea {
    max-height: calc(100vh - 500px);
    resize: none;
    font-family: $font-family;
}

/*
 * Dialogs
 */
@import '@angular/cdk/overlay-prebuilt.css';
.krizak-dialog {
    min-width: 50vw;

    mat-dialog-container {
        padding: 0;
        border-radius: 0;
    }
}

@import 'app/pages/main/dialogs/item-edit/item-edit.dialog.scss';
@import 'app/pages/main/main.page.scss';
@import 'app/pages/login/login.page.scss';