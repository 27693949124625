$font-family: 'Roboto', sans-serif;

$text-primary: #000000;
$text-secondary: #8B7F76;
$bg-primary: #f6f6f6;
$bg-active: #ffffff;
$bg-header: #EEEEEE;
$shadow-color: #CCCCCC;
$separator-color: #CCCCCC;

$font-primary: 16px;
$font-secondary: 12px;
$padding-list: 16px 24px; 