@import 'src/variables.scss';

main-page {
    display: block;
    background-color: $bg-primary;

    .view {
        display: none;

        &.visible {
            display: flex;
            flex-direction: row;
        }
    }

    .column-header {
        padding: $padding-list;
        background-color: $bg-header;

        &--action {
            float: right;
            cursor: pointer;

            & + & {
                margin-right: 16px;
                width: 16px;
                height: 16px;
            }

            &.edit::after {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNi4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTM2Mi43IDE5LjNMMzE0LjMgNjcuNyA0NDQuMyAxOTcuN2w0OC40LTQ4LjRjMjUtMjUgMjUtNjUuNSAwLTkwLjVMNDUzLjMgMTkuM2MtMjUtMjUtNjUuNS0yNS05MC41IDB6bS03MSA3MUw1OC42IDMyMy41Yy0xMC40IDEwLjQtMTggMjMuMy0yMi4yIDM3LjRMMSA0ODEuMkMtMS41IDQ4OS43IC44IDQ5OC44IDcgNTA1czE1LjMgOC41IDIzLjcgNi4xbDEyMC4zLTM1LjRjMTQuMS00LjIgMjctMTEuOCAzNy40LTIyLjJMNDIxLjcgMjIwLjMgMjkxLjcgOTAuM3oiLz48L3N2Zz4=');
                background-size: contain;
                background-repeat: no-repeat;
                opacity: 0.5;
            }

            &.delete::after {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNi4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTEzNS4yIDE3LjdMMTI4IDMyIDMyIDMyQzE0LjMgMzIgMCA0Ni4zIDAgNjRTMTQuMyA5NiAzMiA5NmwzODQgMGMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMmwtOTYgMC03LjItMTQuM0MzMDcuNCA2LjggMjk2LjMgMCAyODQuMiAwTDE2My44IDBjLTEyLjEgMC0yMy4yIDYuOC0yOC42IDE3Ljd6TTQxNiAxMjhMMzIgMTI4IDUzLjIgNDY3YzEuNiAyNS4zIDIyLjYgNDUgNDcuOSA0NWwyNDUuOCAwYzI1LjMgMCA0Ni4zLTE5LjcgNDcuOS00NUw0MTYgMTI4eiIvPjwvc3ZnPg==');
                background-size: contain;
                background-repeat: no-repeat;
                opacity: 0.5;
            }
        }

        &--role {
            font-size: $font-secondary;
            color: $text-secondary;
        }

        &--person {
            margin-top: 8px;
            font-size: $font-primary;
            font-weight: bold;
            color: $text-primary;
        }

        &--description-title {
            margin-top: 16px;
            font-size: $font-secondary;
            color: $text-secondary;
        }

        &--description {
            margin-top: 8px;
            margin-bottom: $font-primary;
            font-size: $font-secondary;
            line-height: 1.5;
            color: $text-primary;
        }

        &--navigate {
            margin-top: 32px;
            color: $text-secondary;
            font-size: $font-secondary;
            line-height: 1.5;
            position: relative;
            cursor: pointer;
            
            &::after {
                content: '';
                color: $text-secondary;
                position: absolute;
                display: flex;
                align-items: center;
                right: 16px;
                top: 0px;
                bottom: 0px;
                width: 10px;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNi4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTMxMC42IDIzMy40YzEyLjUgMTIuNSAxMi41IDMyLjggMCA0NS4zbC0xOTIgMTkyYy0xMi41IDEyLjUtMzIuOCAxMi41LTQ1LjMgMHMtMTIuNS0zMi44IDAtNDUuM0wyNDIuNyAyNTYgNzMuNCA4Ni42Yy0xMi41LTEyLjUtMTIuNS0zMi44IDAtNDUuM3MzMi44LTEyLjUgNDUuMyAwbDE5MiAxOTJ6Ii8+PC9zdmc+');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }

    .list-item {
        display: flex;
        flex-direction: column;
        padding: $padding-list;
        position: relative;
        cursor: pointer;
        user-select: none;

        &.active {
            background-color: $bg-active;
            box-shadow: 0 0 44px $shadow-color;

            &::after {
                content: '';
                color: $text-secondary;
                position: absolute;
                display: flex;
                align-items: center;
                right: 16px;
                top: 0px;
                bottom: 0px;
                width: 16px;
                opacity: 0.5;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNi4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTMxMC42IDIzMy40YzEyLjUgMTIuNSAxMi41IDMyLjggMCA0NS4zbC0xOTIgMTkyYy0xMi41IDEyLjUtMzIuOCAxMi41LTQ1LjMgMHMtMTIuNS0zMi44IDAtNDUuM0wyNDIuNyAyNTYgNzMuNCA4Ni42Yy0xMi41LTEyLjUtMTIuNS0zMi44IDAtNDUuM3MzMi44LTEyLjUgNDUuMyAwbDE5MiAxOTJ6Ii8+PC9zdmc+');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        &--role {
            font-size: $font-secondary;
            color: $text-secondary;
        }

        &--unit {
            margin-top: 8px;
            font-size: $font-primary;
            font-weight: bold;
            color: $text-primary;
        }
    }

    .list-item-add {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: $font-secondary;
        color: $text-secondary;
        margin-top: 16px;

        .icon-add::after {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 16px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNi4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTI1NiA4MGMwLTE3LjctMTQuMy0zMi0zMi0zMnMtMzIgMTQuMy0zMiAzMmwwIDE0NEw0OCAyMjRjLTE3LjcgMC0zMiAxNC4zLTMyIDMyczE0LjMgMzIgMzIgMzJsMTQ0IDAgMCAxNDRjMCAxNy43IDE0LjMgMzIgMzIgMzJzMzItMTQuMyAzMi0zMmwwLTE0NCAxNDQgMGMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMmwtMTQ0IDAgMC0xNDR6Ii8+PC9zdmc+');
            background-size: contain;
            background-repeat: no-repeat;
            opacity: 0.5;
        }
    }

    .column {
        display: flex;
        flex-direction: column;
        width: 25%;
        height: 100vh;
        background-color: $bg-primary;
        clip-path: margin-box;
        border-right: 1px solid $separator-color;
        overflow: auto;

        &.column-detail {
            // clip top, right, bottom. left is not clipped
            clip-path: polygon(-100% 0, 100% 0, 100% 100%, -100% 100%);
            background-color: $bg-active;
            box-shadow: 0 0 44px $shadow-color;
            z-index: 1;

            .column-header {
                background-color: $bg-active;
            }
        }
    }



    .search-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: $padding-list;
        font-size: $font-secondary;
        color: $text-secondary;
        cursor: pointer;

        .icon-search {
            display: inline-block;
            width: 16px;
            height: 16px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICB2aWV3Qm94PSIwIDAgMzAgMzAiIHdpZHRoPSIzMHB4IiBoZWlnaHQ9IjMwcHgiPjxwYXRoIGQ9Ik0gMTMgMyBDIDcuNDg4OTk3MSAzIDMgNy40ODg5OTcxIDMgMTMgQyAzIDE4LjUxMTAwMyA3LjQ4ODk5NzEgMjMgMTMgMjMgQyAxNS4zOTY1MDggMjMgMTcuNTk3Mzg1IDIyLjE0ODk4NiAxOS4zMjIyNjYgMjAuNzM2MzI4IEwgMjUuMjkyOTY5IDI2LjcwNzAzMSBBIDEuMDAwMSAxLjAwMDEgMCAxIDAgMjYuNzA3MDMxIDI1LjI5Mjk2OSBMIDIwLjczNjMyOCAxOS4zMjIyNjYgQyAyMi4xNDg5ODYgMTcuNTk3Mzg1IDIzIDE1LjM5NjUwOCAyMyAxMyBDIDIzIDcuNDg4OTk3MSAxOC41MTEwMDMgMyAxMyAzIHogTSAxMyA1IEMgMTcuNDMwMTIzIDUgMjEgOC41Njk4Nzc0IDIxIDEzIEMgMjEgMTcuNDMwMTIzIDE3LjQzMDEyMyAyMSAxMyAyMSBDIDguNTY5ODc3NCAyMSA1IDE3LjQzMDEyMyA1IDEzIEMgNSA4LjU2OTg3NzQgOC41Njk4Nzc0IDUgMTMgNSB6Ii8+PC9zdmc+');
            background-size: contain;
            opacity: 0.5;
        }
    }

    .search-input {
        display: flex;
        flex-direction: row;   
        align-items: center;
        justify-content: space-between;
        padding: $padding-list;
        font-size: $font-primary;
        font-weight: bold;
        color: $text-primary;

        .icon-close {
            display: inline-block;
            width: 16px;
            height: 16px;
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICB2aWV3Qm94PSIwIDAgMzAgMzAiIHdpZHRoPSIzMHB4IiBoZWlnaHQ9IjMwcHgiPiAgICA8cGF0aCBkPSJNIDcgNCBDIDYuNzQ0MTI1IDQgNi40ODc5Njg3IDQuMDk3NDY4NyA2LjI5Mjk2ODggNC4yOTI5Njg4IEwgNC4yOTI5Njg4IDYuMjkyOTY4OCBDIDMuOTAxOTY4NyA2LjY4Mzk2ODggMy45MDE5Njg3IDcuMzE3MDMxMyA0LjI5Mjk2ODggNy43MDcwMzEyIEwgMTEuNTg1OTM4IDE1IEwgNC4yOTI5Njg4IDIyLjI5Mjk2OSBDIDMuOTAxOTY4NyAyMi42ODM5NjkgMy45MDE5Njg3IDIzLjMxNzAzMSA0LjI5Mjk2ODggMjMuNzA3MDMxIEwgNi4yOTI5Njg4IDI1LjcwNzAzMSBDIDYuNjgzOTY4OCAyNi4wOTgwMzEgNy4zMTcwMzEzIDI2LjA5ODAzMSA3LjcwNzAzMTIgMjUuNzA3MDMxIEwgMTUgMTguNDE0MDYyIEwgMjIuMjkyOTY5IDI1LjcwNzAzMSBDIDIyLjY4Mjk2OSAyNi4wOTgwMzEgMjMuMzE3MDMxIDI2LjA5ODAzMSAyMy43MDcwMzEgMjUuNzA3MDMxIEwgMjUuNzA3MDMxIDIzLjcwNzAzMSBDIDI2LjA5ODAzMSAyMy4zMTYwMzEgMjYuMDk4MDMxIDIyLjY4Mjk2OSAyNS43MDcwMzEgMjIuMjkyOTY5IEwgMTguNDE0MDYyIDE1IEwgMjUuNzA3MDMxIDcuNzA3MDMxMiBDIDI2LjA5ODAzMSA3LjMxNzAzMTIgMjYuMDk4MDMxIDYuNjgyOTY4OCAyNS43MDcwMzEgNi4yOTI5Njg4IEwgMjMuNzA3MDMxIDQuMjkyOTY4OCBDIDIzLjMxNjAzMSAzLjkwMTk2ODcgMjIuNjgyOTY5IDMuOTAxOTY4NyAyMi4yOTI5NjkgNC4yOTI5Njg4IEwgMTUgMTEuNTg1OTM4IEwgNy43MDcwMzEyIDQuMjkyOTY4OCBDIDcuNTExNTMxMiA0LjA5NzQ2ODcgNy4yNTU4NzUgNCA3IDQgeiIvPjwvc3ZnPg==');
            background-size: contain;
            opacity: 0.5;
            cursor: pointer;
        }
    }

    .btn-login, .btn-logout {
        position: fixed;
        left: 16px;
        bottom: 16px;
        width: 16px;
        height: 16px;        
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNi4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTIxNy45IDEwNS45TDM0MC43IDIyOC43YzcuMiA3LjIgMTEuMyAxNy4xIDExLjMgMjcuM3MtNC4xIDIwLjEtMTEuMyAyNy4zTDIxNy45IDQwNi4xYy02LjQgNi40LTE1IDkuOS0yNCA5LjljLTE4LjcgMC0zMy45LTE1LjItMzMuOS0zMy45bDAtNjIuMUwzMiAzMjBjLTE3LjcgMC0zMi0xNC4zLTMyLTMybDAtNjRjMC0xNy43IDE0LjMtMzIgMzItMzJsMTI4IDAgMC02Mi4xYzAtMTguNyAxNS4yLTMzLjkgMzMuOS0zMy45YzkgMCAxNy42IDMuNiAyNCA5Ljl6TTM1MiA0MTZsNjQgMGMxNy43IDAgMzItMTQuMyAzMi0zMmwwLTI1NmMwLTE3LjctMTQuMy0zMi0zMi0zMmwtNjQgMGMtMTcuNyAwLTMyLTE0LjMtMzItMzJzMTQuMy0zMiAzMi0zMmw2NCAwYzUzIDAgOTYgNDMgOTYgOTZsMCAyNTZjMCA1My00MyA5Ni05NiA5NmwtNjQgMGMtMTcuNyAwLTMyLTE0LjMtMzItMzJzMTQuMy0zMiAzMi0zMnoiLz48L3N2Zz4=');
        background-size: contain;
        opacity: 0.5;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }

}