/* position */
.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
}
.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}
.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}
.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}
.toast-top-left {
    top: 12px;
    left: 12px;
}
.toast-top-right {
    top: 12px;
    right: 12px;
}
.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}
.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
}

.toast-container * {
    box-sizing: border-box;
}

.toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 0.9375rem;
    width: 300px;
    border-radius: 0.9375rem;
    box-shadow: 0 17px 25px -14px #dfd3ca;
    background-color: var(--color-white);
    color: var(--color-green-dark);
    pointer-events: auto;
}

.toast-container .ngx-toastr:hover {
    opacity: 1;
    cursor: pointer;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
}

.hchkr-toast {
    &-title {
        font-weight: bold;
        font-family: var(--font-family);
        margin-bottom: .1875rem;
    }

    &-message {
        font-family: var(--font-family);
        font-size: 0.875rem;
    }

    .hchkr-item__icon {
        border-radius: 0.9375rem;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 0.9375rem;
        background-color: var(--color-background);
        color: var(--color-cocoa-medium);

        .material-icons-outlined::after {
            content: "lightbulb";
        }
    }
}

.toast-success {
    .hchkr-item__icon {
        background-color: var(--color-green-light);
        color: var(--color-green-medium);

        .material-icons-outlined::after {
            content: "done";
        }
    }
}

.toast-error {
    .hchkr-item__icon {
        background-color: var(--color-coral-light);
        color: var(--color-coral-dark);

        .material-icons-outlined::after {
            content: "close";
        }
    }
}

.toast-warning {
    .hchkr-item__icon {
        background-color: var(--color-yellow-light);
        color: var(--color-yellow-dark);

        .material-icons-outlined::after {
            content: "priority_high";
        }
    }
}

.toast-info {
    .hchkr-item__icon {
        background-color: var(--color-background);
        color: var(--color-cocoa-medium);

        .material-icons-outlined::after {
            content: "lightbulb";
        }
    }
}

/* Responsive Design */
@media all and (max-width: 240px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 11em;
    }
    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}
@media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 18em;
    }
    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}
@media all and (min-width: 481px) and (max-width: 768px) {
    .toast-container .ngx-toastr.div {
        padding: 15px 15px 15px 50px;
        width: 25em;
    }
}
