/* position */
@import '@angular/cdk/overlay-prebuilt.css';
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 0.9375rem;
  width: 300px;
  border-radius: 0.9375rem;
  box-shadow: 0 17px 25px -14px #dfd3ca;
  background-color: var(--color-white);
  color: var(--color-green-dark);
  pointer-events: auto;
}

.toast-container .ngx-toastr:hover {
  opacity: 1;
  cursor: pointer;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

.hchkr-toast-title {
  font-weight: bold;
  font-family: var(--font-family);
  margin-bottom: 0.1875rem;
}
.hchkr-toast-message {
  font-family: var(--font-family);
  font-size: 0.875rem;
}
.hchkr-toast .hchkr-item__icon {
  border-radius: 0.9375rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 0.9375rem;
  background-color: var(--color-background);
  color: var(--color-cocoa-medium);
}
.hchkr-toast .hchkr-item__icon .material-icons-outlined::after {
  content: "lightbulb";
}

.toast-success .hchkr-item__icon {
  background-color: var(--color-green-light);
  color: var(--color-green-medium);
}
.toast-success .hchkr-item__icon .material-icons-outlined::after {
  content: "done";
}

.toast-error .hchkr-item__icon {
  background-color: var(--color-coral-light);
  color: var(--color-coral-dark);
}
.toast-error .hchkr-item__icon .material-icons-outlined::after {
  content: "close";
}

.toast-warning .hchkr-item__icon {
  background-color: var(--color-yellow-light);
  color: var(--color-yellow-dark);
}
.toast-warning .hchkr-item__icon .material-icons-outlined::after {
  content: "priority_high";
}

.toast-info .hchkr-item__icon {
  background-color: var(--color-background);
  color: var(--color-cocoa-medium);
}
.toast-info .hchkr-item__icon .material-icons-outlined::after {
  content: "lightbulb";
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

input, textarea {
  width: 100%;
  outline: none;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #8B7F76;
}

textarea {
  max-height: calc(100vh - 500px);
  resize: none;
  font-family: "Roboto", sans-serif;
}

/*
 * Dialogs
 */
.krizak-dialog {
  min-width: 50vw;
}
.krizak-dialog mat-dialog-container {
  padding: 0;
  border-radius: 0;
}

dialog-item-edit {
  background-color: #f6f6f6;
}
dialog-item-edit .form {
  background-color: #EEEEEE;
  padding: 32px 16px;
  box-shadow: 0 0 44px #CCCCCC;
}
dialog-item-edit .form * + * {
  margin-top: 16px;
}

main-page {
  display: block;
  background-color: #f6f6f6;
}
main-page .view {
  display: none;
}
main-page .view.visible {
  display: flex;
  flex-direction: row;
}
main-page .column-header {
  padding: 16px 24px;
  background-color: #EEEEEE;
}
main-page .column-header--action {
  float: right;
  cursor: pointer;
}
main-page .column-header--action + main-page .column-header--action {
  margin-right: 16px;
  width: 16px;
  height: 16px;
}
main-page .column-header--action.edit::after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNi4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTM2Mi43IDE5LjNMMzE0LjMgNjcuNyA0NDQuMyAxOTcuN2w0OC40LTQ4LjRjMjUtMjUgMjUtNjUuNSAwLTkwLjVMNDUzLjMgMTkuM2MtMjUtMjUtNjUuNS0yNS05MC41IDB6bS03MSA3MUw1OC42IDMyMy41Yy0xMC40IDEwLjQtMTggMjMuMy0yMi4yIDM3LjRMMSA0ODEuMkMtMS41IDQ4OS43IC44IDQ5OC44IDcgNTA1czE1LjMgOC41IDIzLjcgNi4xbDEyMC4zLTM1LjRjMTQuMS00LjIgMjctMTEuOCAzNy40LTIyLjJMNDIxLjcgMjIwLjMgMjkxLjcgOTAuM3oiLz48L3N2Zz4=");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.5;
}
main-page .column-header--action.delete::after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNi4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTEzNS4yIDE3LjdMMTI4IDMyIDMyIDMyQzE0LjMgMzIgMCA0Ni4zIDAgNjRTMTQuMyA5NiAzMiA5NmwzODQgMGMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMmwtOTYgMC03LjItMTQuM0MzMDcuNCA2LjggMjk2LjMgMCAyODQuMiAwTDE2My44IDBjLTEyLjEgMC0yMy4yIDYuOC0yOC42IDE3Ljd6TTQxNiAxMjhMMzIgMTI4IDUzLjIgNDY3YzEuNiAyNS4zIDIyLjYgNDUgNDcuOSA0NWwyNDUuOCAwYzI1LjMgMCA0Ni4zLTE5LjcgNDcuOS00NUw0MTYgMTI4eiIvPjwvc3ZnPg==");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.5;
}
main-page .column-header--role {
  font-size: 12px;
  color: #8B7F76;
}
main-page .column-header--person {
  margin-top: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
main-page .column-header--description-title {
  margin-top: 16px;
  font-size: 12px;
  color: #8B7F76;
}
main-page .column-header--description {
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 1.5;
  color: #000000;
}
main-page .column-header--navigate {
  margin-top: 32px;
  color: #8B7F76;
  font-size: 12px;
  line-height: 1.5;
  position: relative;
  cursor: pointer;
}
main-page .column-header--navigate::after {
  content: "";
  color: #8B7F76;
  position: absolute;
  display: flex;
  align-items: center;
  right: 16px;
  top: 0px;
  bottom: 0px;
  width: 10px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNi4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTMxMC42IDIzMy40YzEyLjUgMTIuNSAxMi41IDMyLjggMCA0NS4zbC0xOTIgMTkyYy0xMi41IDEyLjUtMzIuOCAxMi41LTQ1LjMgMHMtMTIuNS0zMi44IDAtNDUuM0wyNDIuNyAyNTYgNzMuNCA4Ni42Yy0xMi41LTEyLjUtMTIuNS0zMi44IDAtNDUuM3MzMi44LTEyLjUgNDUuMyAwbDE5MiAxOTJ6Ii8+PC9zdmc+");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
main-page .list-item {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  position: relative;
  cursor: pointer;
  user-select: none;
}
main-page .list-item.active {
  background-color: #ffffff;
  box-shadow: 0 0 44px #CCCCCC;
}
main-page .list-item.active::after {
  content: "";
  color: #8B7F76;
  position: absolute;
  display: flex;
  align-items: center;
  right: 16px;
  top: 0px;
  bottom: 0px;
  width: 16px;
  opacity: 0.5;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNi4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTMxMC42IDIzMy40YzEyLjUgMTIuNSAxMi41IDMyLjggMCA0NS4zbC0xOTIgMTkyYy0xMi41IDEyLjUtMzIuOCAxMi41LTQ1LjMgMHMtMTIuNS0zMi44IDAtNDUuM0wyNDIuNyAyNTYgNzMuNCA4Ni42Yy0xMi41LTEyLjUtMTIuNS0zMi44IDAtNDUuM3MzMi44LTEyLjUgNDUuMyAwbDE5MiAxOTJ6Ii8+PC9zdmc+");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
main-page .list-item--role {
  font-size: 12px;
  color: #8B7F76;
}
main-page .list-item--unit {
  margin-top: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
main-page .list-item-add {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  color: #8B7F76;
  margin-top: 16px;
}
main-page .list-item-add .icon-add::after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNi4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTI1NiA4MGMwLTE3LjctMTQuMy0zMi0zMi0zMnMtMzIgMTQuMy0zMiAzMmwwIDE0NEw0OCAyMjRjLTE3LjcgMC0zMiAxNC4zLTMyIDMyczE0LjMgMzIgMzIgMzJsMTQ0IDAgMCAxNDRjMCAxNy43IDE0LjMgMzIgMzIgMzJzMzItMTQuMyAzMi0zMmwwLTE0NCAxNDQgMGMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMmwtMTQ0IDAgMC0xNDR6Ii8+PC9zdmc+");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.5;
}
main-page .column {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100vh;
  background-color: #f6f6f6;
  clip-path: margin-box;
  border-right: 1px solid #CCCCCC;
  overflow: auto;
}
main-page .column.column-detail {
  clip-path: polygon(-100% 0, 100% 0, 100% 100%, -100% 100%);
  background-color: #ffffff;
  box-shadow: 0 0 44px #CCCCCC;
  z-index: 1;
}
main-page .column.column-detail .column-header {
  background-color: #ffffff;
}
main-page .search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  font-size: 12px;
  color: #8B7F76;
  cursor: pointer;
}
main-page .search-bar .icon-search {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICB2aWV3Qm94PSIwIDAgMzAgMzAiIHdpZHRoPSIzMHB4IiBoZWlnaHQ9IjMwcHgiPjxwYXRoIGQ9Ik0gMTMgMyBDIDcuNDg4OTk3MSAzIDMgNy40ODg5OTcxIDMgMTMgQyAzIDE4LjUxMTAwMyA3LjQ4ODk5NzEgMjMgMTMgMjMgQyAxNS4zOTY1MDggMjMgMTcuNTk3Mzg1IDIyLjE0ODk4NiAxOS4zMjIyNjYgMjAuNzM2MzI4IEwgMjUuMjkyOTY5IDI2LjcwNzAzMSBBIDEuMDAwMSAxLjAwMDEgMCAxIDAgMjYuNzA3MDMxIDI1LjI5Mjk2OSBMIDIwLjczNjMyOCAxOS4zMjIyNjYgQyAyMi4xNDg5ODYgMTcuNTk3Mzg1IDIzIDE1LjM5NjUwOCAyMyAxMyBDIDIzIDcuNDg4OTk3MSAxOC41MTEwMDMgMyAxMyAzIHogTSAxMyA1IEMgMTcuNDMwMTIzIDUgMjEgOC41Njk4Nzc0IDIxIDEzIEMgMjEgMTcuNDMwMTIzIDE3LjQzMDEyMyAyMSAxMyAyMSBDIDguNTY5ODc3NCAyMSA1IDE3LjQzMDEyMyA1IDEzIEMgNSA4LjU2OTg3NzQgOC41Njk4Nzc0IDUgMTMgNSB6Ii8+PC9zdmc+");
  background-size: contain;
  opacity: 0.5;
}
main-page .search-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
main-page .search-input .icon-close {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICB2aWV3Qm94PSIwIDAgMzAgMzAiIHdpZHRoPSIzMHB4IiBoZWlnaHQ9IjMwcHgiPiAgICA8cGF0aCBkPSJNIDcgNCBDIDYuNzQ0MTI1IDQgNi40ODc5Njg3IDQuMDk3NDY4NyA2LjI5Mjk2ODggNC4yOTI5Njg4IEwgNC4yOTI5Njg4IDYuMjkyOTY4OCBDIDMuOTAxOTY4NyA2LjY4Mzk2ODggMy45MDE5Njg3IDcuMzE3MDMxMyA0LjI5Mjk2ODggNy43MDcwMzEyIEwgMTEuNTg1OTM4IDE1IEwgNC4yOTI5Njg4IDIyLjI5Mjk2OSBDIDMuOTAxOTY4NyAyMi42ODM5NjkgMy45MDE5Njg3IDIzLjMxNzAzMSA0LjI5Mjk2ODggMjMuNzA3MDMxIEwgNi4yOTI5Njg4IDI1LjcwNzAzMSBDIDYuNjgzOTY4OCAyNi4wOTgwMzEgNy4zMTcwMzEzIDI2LjA5ODAzMSA3LjcwNzAzMTIgMjUuNzA3MDMxIEwgMTUgMTguNDE0MDYyIEwgMjIuMjkyOTY5IDI1LjcwNzAzMSBDIDIyLjY4Mjk2OSAyNi4wOTgwMzEgMjMuMzE3MDMxIDI2LjA5ODAzMSAyMy43MDcwMzEgMjUuNzA3MDMxIEwgMjUuNzA3MDMxIDIzLjcwNzAzMSBDIDI2LjA5ODAzMSAyMy4zMTYwMzEgMjYuMDk4MDMxIDIyLjY4Mjk2OSAyNS43MDcwMzEgMjIuMjkyOTY5IEwgMTguNDE0MDYyIDE1IEwgMjUuNzA3MDMxIDcuNzA3MDMxMiBDIDI2LjA5ODAzMSA3LjMxNzAzMTIgMjYuMDk4MDMxIDYuNjgyOTY4OCAyNS43MDcwMzEgNi4yOTI5Njg4IEwgMjMuNzA3MDMxIDQuMjkyOTY4OCBDIDIzLjMxNjAzMSAzLjkwMTk2ODcgMjIuNjgyOTY5IDMuOTAxOTY4NyAyMi4yOTI5NjkgNC4yOTI5Njg4IEwgMTUgMTEuNTg1OTM4IEwgNy43MDcwMzEyIDQuMjkyOTY4OCBDIDcuNTExNTMxMiA0LjA5NzQ2ODcgNy4yNTU4NzUgNCA3IDQgeiIvPjwvc3ZnPg==");
  background-size: contain;
  opacity: 0.5;
  cursor: pointer;
}
main-page .btn-login, main-page .btn-logout {
  position: fixed;
  left: 16px;
  bottom: 16px;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNi4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTIxNy45IDEwNS45TDM0MC43IDIyOC43YzcuMiA3LjIgMTEuMyAxNy4xIDExLjMgMjcuM3MtNC4xIDIwLjEtMTEuMyAyNy4zTDIxNy45IDQwNi4xYy02LjQgNi40LTE1IDkuOS0yNCA5LjljLTE4LjcgMC0zMy45LTE1LjItMzMuOS0zMy45bDAtNjIuMUwzMiAzMjBjLTE3LjcgMC0zMi0xNC4zLTMyLTMybDAtNjRjMC0xNy43IDE0LjMtMzIgMzItMzJsMTI4IDAgMC02Mi4xYzAtMTguNyAxNS4yLTMzLjkgMzMuOS0zMy45YzkgMCAxNy42IDMuNiAyNCA5Ljl6TTM1MiA0MTZsNjQgMGMxNy43IDAgMzItMTQuMyAzMi0zMmwwLTI1NmMwLTE3LjctMTQuMy0zMi0zMi0zMmwtNjQgMGMtMTcuNyAwLTMyLTE0LjMtMzItMzJzMTQuMy0zMiAzMi0zMmw2NCAwYzUzIDAgOTYgNDMgOTYgOTZsMCAyNTZjMCA1My00MyA5Ni05NiA5NmwtNjQgMGMtMTcuNyAwLTMyLTE0LjMtMzItMzJzMTQuMy0zMiAzMi0zMnoiLz48L3N2Zz4=");
  background-size: contain;
  opacity: 0.5;
  cursor: pointer;
}
main-page .btn-login:hover, main-page .btn-logout:hover {
  opacity: 1;
}

page-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  width: 100%;
  height: 100vh;
}
page-login .form {
  background-color: #EEEEEE;
  padding: 32px 16px;
  box-shadow: 0 0 44px #CCCCCC;
}
page-login .form * + * {
  margin-top: 16px;
}