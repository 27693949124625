@import 'src/variables.scss';

dialog-item-edit {
    background-color: $bg-primary;

    .form {
        background-color: $bg-header;
        padding: 32px 16px;
        box-shadow: 0 0 44px $shadow-color;

        *+* {
            margin-top: 16px;
        }
    }
}