@import 'src/variables.scss';

page-login {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: $bg-primary;
    width: 100%;
    height: 100vh;

    .form {
        background-color: $bg-header;
        padding: 32px 16px;
        box-shadow: 0 0 44px $shadow-color;

        *+* {
            margin-top: 16px;
        }
    }
}